import { DISPLAY_MESSAGES } from './constants'
import { WIDGET_CATEGORY_ID_ENUMS } from './widget-constants'

export const errorHandle = (message, data, status, isContact = false) => {
  return {
    message: message,
    data: data,
    status: status,
    isContact: isContact,
    isAddressMissing: data ? data.isAddressMissing : false,
  }
}

export const customErrorValidation = (type, result, key) => {
  if (key === WIDGET_CATEGORY_ID_ENUMS.CONTENT || key === WIDGET_CATEGORY_ID_ENUMS.ENGAGEMENT || key === WIDGET_CATEGORY_ID_ENUMS.RECREATION) {
    if (type === 'success') {
      /* placehoder code */
    } else {
      return errorHandle(DISPLAY_MESSAGES.GENERIC_ERROR, result, result.status, true)
    }
  }
  if (key === WIDGET_CATEGORY_ID_ENUMS.CALENDAR) {
    if (type === 'success') {
      if (!result.data.eventsResults.length) {
        throw result
      }
    } else {
      return errorHandle(DISPLAY_MESSAGES.CALENDAR_EVENTS_EMPTY, result, result.status, false)
    }
  }
  if (key === WIDGET_CATEGORY_ID_ENUMS.NEWS) {
    if (type === 'success') {
      if (!result.data.newsResult.response.length) {
        throw result
      }
    } else {
      return errorHandle(DISPLAY_MESSAGES.NEWS_EMPTY, result, result.status, false)
    }
  }
  if (key === WIDGET_CATEGORY_ID_ENUMS.LOCATION) {
    if (type === 'success') {
      if (result.isAddressMissing || (!result.data.locationWidgetResult || (!result.data.locationWidgetResult.htmlContent && !result.data.locationWidgetResult.htmlExpandedContent && !result.data.locationWidgetResult.formatContent)) || (result.data.errorMessage)) {
        throw result
      }
    } else {
      if (result?.isAddressMissing) {
        return errorHandle(DISPLAY_MESSAGES.ADDRESS_ERROR, result, result.status, true)
      } else if (result?.data?.errorMessage) {
        return errorHandle(result.data.errorMessage, result, result.status, false)
      } else {
        return errorHandle(DISPLAY_MESSAGES.ADDRESS_ERROR, result, result.status, true)
      }
    }
  }
  if (key === WIDGET_CATEGORY_ID_ENUMS.INTEGRATION) {
    if (type === 'success') {
      if (result.isAddressMissing || (result.data.isLinked && !result.data.integrationWidgetResult) || (result.data.isLinked && !result.data.integrationWidgetResult?.htmlContent &&
        !result.data.integrationWidgetResult?.htmlExpandedContent &&
        !result.data.integrationWidgetResult?.formatContent) || (result.data.errorMessage)) {
        throw result
      }
    } else {
      if (result?.isAddressMissing) {
        return errorHandle(DISPLAY_MESSAGES.ADDRESS_ERROR, result, result.status, true)
      } else if (result?.data?.errorMessage) {
        return errorHandle(result.data.errorMessage, result, result.status, false)
      } else {
        return errorHandle(DISPLAY_MESSAGES.GENERIC_ERROR, result, result.status, true)
      }
    }
  }
  if (key === WIDGET_CATEGORY_ID_ENUMS.FORMBUILDER) {
    if (type === 'success') {
      if (result.isAddressMissing) {
        throw result
      }
    } else {
      if (result?.isAddressMissing) {
        return errorHandle(DISPLAY_MESSAGES.ADDRESS_ERROR, result, result.status, true)
      } else {
        return errorHandle(DISPLAY_MESSAGES.GENERIC_ERROR, result, result.status, true)
      }
    }
  }
  if (key === WIDGET_CATEGORY_ID_ENUMS.MYWASTE) {
    if (type === 'success') {
      if (result.isAddressMissing) {
        throw result
      }
      const isZoneMissing = result.data.isZoneMissing
      const eventResults = result.data.eventsResults
      if (!isZoneMissing && eventResults && eventResults.status.toLowerCase() === 'success' && eventResults.data && eventResults.data.schedules.length > 0) {
        return result
      } else {
        throw result
      }
    } else {
      return errorHandle(DISPLAY_MESSAGES.ADDRESS_ERROR, result, result.status, true)
    }
  }
  return result
}
