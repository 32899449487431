<template>
  <div>
    <ErrorHandler v-if="errorMessage" :error="errorMessage" :contactUsUrl="contactUsUrl" />
    <template v-else>
      <RecreationCategories v-if="!showRecreationClasses" :loading="categoriesLoading" @onShowRecreationClasses="onShowRecreationClasses" />
      <RecreationClasses v-else @onShowRecreationClasses="onShowRecreationClasses" />
    </template>
  </div>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { useFormatPayload } from '../../../composables/useFormatPayload'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { errorHandle } from '../../../utilities/error-handle'
import { DISPLAY_MESSAGES } from '../../../utilities/constants'
export default {
  name: 'ExpandedViewContent',
  setup() {
    const { isLoading: locationLoading, response: locationRes, error: locationError, callApi: locationApi } = useAxios()
    const { isLoading: categoriesLoading, response: categoriesRes, error: categoriesError, callApi: categoriesApi } = useAxios()
    const { formatRequestPayload } = useFormatPayload()
    return { formatRequestPayload, locationLoading, locationRes, locationError, locationApi, categoriesLoading, categoriesRes, categoriesError, categoriesApi }
  },
  inject: {
    mode: {
      default: '',
    },
    locationURL: {
      default: null,
    },
    categoriesURL: {
      default: null,
    },
  },
  data() {
    return {
      showRecreationClasses: false,
      errorMessage: null,
    }
  },
  components: {
    RecreationCategories: () => import('./RecreationCategories.vue'),
    RecreationClasses: () => import('./RecreationClasses.vue'),
    ErrorHandler: () => import('../../common/ErrorHandler.vue'),
  },
  computed: {
    ...mapState({
      contactUsUrl: (state) => state.shared.contactUsUrl,
      recreationWidgetFilterForm: (state) => state.shared.recreationWidgetFilterForm,
    }),
  },
  created() {
    this.getLocations()
    this.getCategories()
  },
  methods: {
    async getLocations() {
      const url = this.mode === 'Preview' ? this.locationURL : ServiceUrls.getRecreationWidgetLocations
      await this.locationApi({ method: 'get', url })
      if (this.locationRes) {
        this.$store.commit('shared/setRecreationWidgetLocations', this.locationRes)
      }
      if (this.locationError) {
        this.errorMessage = errorHandle(DISPLAY_MESSAGES.GENERIC_ERROR, this.locationError.data, this.locationError.status, this.contactUsUrl)
      }
    },
    async getCategories() {
      const url = this.mode === 'Preview' ? this.categoriesURL : ServiceUrls.getRecreationWidgetCategories
      await this.categoriesApi({ method: 'post', url, data: this.formatRequestPayload(this.recreationWidgetFilterForm) })
      if (this.categoriesRes) {
        this.$store.commit('shared/setRecreationWidgetCategories', this.categoriesRes)
      }
      if (this.categoriesError) {
        if (!axios.isCancel(this.categoriesError)) {
          this.errorMessage = errorHandle(DISPLAY_MESSAGES.GENERIC_ERROR, this.categoriesError.data, this.categoriesError.status, this.contactUsUrl)
        }
      }
    },
    onShowRecreationClasses(program) {
      this.showRecreationClasses = !!program
      this.$store.commit('shared/setRecreationWidgetSelectedProgram', this.showRecreationClasses ? program : '')
    },
  },
  watch: {
    recreationWidgetFilterForm: {
      deep: true,
      handler() {
        this.getCategories()
      },
    },
  },
  destroyed() {
    this.$store.commit('shared/setRecreationWidgetFilterForm', null)
    this.$store.commit('shared/setRecreationWidgetClassesFilterForm', null)
  },
}
</script>
