import { ref } from 'vue'
import moment from 'moment'

export function useFormatPayload() {
  const momentDateFormat = ref('YYYY-MM-DD')
  const recreationClassesSearchDuration = ref(Number(process.env.VUE_APP_RECREATIONCLASSESSEARCHDURATION) || 60)

  function formatRequestPayload(formData) {
    const postObj = {
      programName: '',
      locationIds: '',
      startDate: '',
      endDate: '',
    }
    if (formData) {
      postObj.locationIds = formData.location.toString()
      if (formData.startDate) {
        const startDate = moment(formData.startDate).format(momentDateFormat.value)
        postObj.startDate = formData.startTime ? `${startDate}T${formData.startTime}` : `${startDate}T00:00`
      }
      if (formData.endDate) {
        const endDate = moment(formData.endDate).format(momentDateFormat.value)
        postObj.endDate = formData.endTime ? `${endDate}T${formData.endTime}` : `${endDate}T23:59`
      }

      if (!formData.startDate && formData.startTime) {
        let startDate = moment().format(momentDateFormat.value)
        if (formData.endDate) {
          const formatEndDate = moment(formData.endDate).format(momentDateFormat.value)
          startDate = moment(formatEndDate)
            .subtract(recreationClassesSearchDuration.value - 1, 'day')
            .format(momentDateFormat.value)
        }
        postObj.startDate = `${startDate}T${formData.startTime}`
      }
      if (!formData.endDate && formData.endTime) {
        let endDate = moment().format(momentDateFormat.value)
        if (formData.startDate) {
          const formatStartDate = moment(formData.startDate).format(momentDateFormat.value)
          endDate = moment(formatStartDate).add(recreationClassesSearchDuration.value, 'day').format(momentDateFormat.value)
        }
        postObj.endDate = `${endDate}T${formData.endTime}`
      }
    }
    return postObj
  }

  return { momentDateFormat, recreationClassesSearchDuration, formatRequestPayload }
}
